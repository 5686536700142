/* eslint-disable lodash/import-scope */
// import { useAllBlockchain } from 'state/home/fetchAllBlockChain'

// import {useWeb3React} from '../../../packages/wagmi/src/useWeb3React'
// import TransactionBridge from './components/TransactionBridge'
// eslint-disable-next-line lodash/import-scope
import DiscordIcon from 'components/Menu/DiscordIcon'
import FacebookIcon from 'components/Menu/FacebookIcon'
import InstagramIcon from 'components/Menu/InstagramIcon'
import LinktreeIcon from 'components/Menu/LinktreeIcon'
import MIcon from 'components/Menu/MIcon'
import RedditIcon from 'components/Menu/RedditIcon'
import TIcon from 'components/Menu/TIcon'
import TelegramIcon from 'components/Menu/TelegramIcon'
import Image from 'next/image'
import styled from 'styled-components'

import XIcon from 'components/Menu/XIcon'
import YoutubeIcon from 'components/Menu/YoutubeIcon'
import * as Styles from './styles'

const FooterContainer = styled.div`
  position: absolute;
  bottom: 40px;
  gap: 22px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
`

const Styled04 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding-top: 200px;
  margin-left: 300px;
  @media (min-width: 768px) {
    padding-top: 300px;
  }
  @media (max-width: 500px) {
    width: 170px;
    padding-top: 275px;
    margin-left: 250px;
  }
`

const Styled03 = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 375px;
  min-height: 586px;
`

const Home = ({ pageSupportedChains }: { pageSupportedChains: number[] }) => {
  return (
    <Styles.StyledHome>
      <Styled03>
        <a href="https://testnet-scan.tingchain.org/" target="_blank" rel="noreferrer">
          <Image src="/images/background/tingx-03.png" alt="bridge" width={761} height={440} />
        </a>
      </Styled03>
      <FooterContainer>
        <a href="https://t.me/TingChain" target="_blank" rel="noreferrer">
          <TelegramIcon />
        </a>
        <a href="https://x.com/TingChains" target="_blank" rel="noreferrer">
          <XIcon />
        </a>
        <a href="https://www.facebook.com/TingChains" target="_blank" rel="noreferrer">
          <FacebookIcon />
        </a>
        <a href="https://www.youtube.com/@TingChain" target="_blank" rel="noreferrer">
          <YoutubeIcon />
        </a>
      </FooterContainer>
    </Styles.StyledHome>
  )
}

export default Home
